.Schedule {
    width: 100%;
    margin-top: .25rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.schedule__item {
    width: calc(100% - 3rem);

    margin: .5rem 1rem;
    padding: 1rem;
    box-shadow: 0 2px 1px 1px rgba(116, 149, 203, 0.38);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.schedule__item.active {
    -webkit-box-shadow: 0px 2px 4px 4px rgba(225,192,70,0.38);
    box-shadow: 0px 2px 4px 4px rgba(225,192,70,0.38);
}

.schedule__item__icon {
    width: 32px;
    height: 32px;

    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

.active .schedule__item__icon {
    -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
    filter: grayscale(0);
}

.schedule__item__title {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 2px;
}

.schedule__item__time {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.6rem;
    letter-spacing: 2px;
}

