@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

.Header {
    display: flex;
    flex-direction: row;
    max-width: 1240px;

    margin: 0 auto;

    align-items: center;
    justify-content: space-between;

    padding: 2rem 0;

    box-shadow: 0 1px 4px rgba(116, 149, 203, 0.8);
}


.logo {
    padding: 0 2rem;
    width: 20%;
}

.logo img {
    width: 64px;
    height: 64px;
}

.title {
    text-align: center;
    font-size: 1.6rem;
    font-weight: 400;
    font-family: 'Roboto Condensed', sans-serif;
    /*margin: auto;*/
}

.switchTheme {
    padding: 0 2rem;
    /*margin: auto;*/
    width: 15%;
    text-align: right;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-family: 'Roboto Condensed', sans-serif;
}

@media (max-width: 540px) {
    .title {
        font-size: 1.4rem;
    }

    .switchTheme {
        font-size: 0.6rem;
    }
}

